import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./Jumbotron.scss";
import imgOne from "../../assets/images/pinky.webp";
import imgThree from "../../assets/images/lippy.webp";
import Button from "../../components/Button";

const images = [{ src: imgOne }, { src: imgThree }];

const Banner = () => {
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        setFadeIn(true);
      }, 300); // duration of the fade-out transition
    }, 10000); // 10 seconds

    return () => clearInterval(interval);
  }, []);

  const handleClick = () => {
    const element = document.getElementById("contact-section");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className="banner">
      <div className="image-container">
        <div
          className={`parallax ${fadeIn ? "fade-in" : "fade-out"}`}
          style={{ backgroundImage: `url(${images[currentImage].src})` }}
        ></div>
      </div>
      <div className="banner-content">
        <span className="banner-text">
          {t("banner-title-3").toLocaleUpperCase()}
          <span className="dot">!</span>{" "}
        </span>
        <Button size="medium" onClick={handleClick}>
          {t("banner-btn")}
        </Button>
      </div>
    </div>
  );
};

export default Banner;
