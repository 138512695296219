import React from "react";
import { useTranslation } from 'react-i18next';

import "./NextSteps.scss";
import phoneIcon from "../../assets/desired-icons/Ergespraech.png";
import chatIcon from "../../assets/desired-icons/chat.png";
import contractIcon from "../../assets/desired-icons/Contract.png";
import startIcon from "../../assets/desired-icons/Steigere.png";
import StepsTrenner01 from "../../assets/images/steps-trenner-01.jpg";

const NextSteps = () => {
  const { t } = useTranslation();

  return (
    <section className="sc--main">
      <div className="ct--main w-container">
        {/* <div className="cmp--text center">
          <p className="h--m txt--green">{t('next-steps')}</p>
          <h2 className="h--l">{t('plan-ahead')}</h2>
        </div> */}
        <div className="header-container center">
          <h1 className="green-title">{t('part-of-team').toLocaleUpperCase()}</h1>
          <h2 className="black-title">{t('schedule-consultation')}</h2>
          <p className="p-3">{t('ready-to-kickstart')}</p>
        </div>
        <div className="steps-trenner-img" >
        <img src={StepsTrenner01} loading="lazy" alt="Service" />
      </div>
        <div className="cmp--ic"  id="contact-section">
          <div className="cmp--step">
            <div className="cmp--stepicon">
              <img src={phoneIcon} loading="lazy" alt="phone icon" className="img--stepico" />
            </div>
            <h2 className="h--s txt--green">{t('introduction')}</h2>
            <p>{t('introduction-text')}</p>
          </div>
          <div className="cmp--arrow">
            <i className="fa-solid fa-arrow-right"></i>
          </div>
          <div className="cmp--step">
            <div className="cmp--stepicon">
              <img src={chatIcon} loading="lazy" alt="chat icon" className="img--stepico _2" />
            </div>
            <h2 className="h--s txt--green">{t('zoom')}</h2>
            <p>{t('zoom-text')}</p>
          </div>
          <div className="cmp--arrow">
            <i className="fa-solid fa-arrow-right"></i>
          </div>
          <div className="cmp--step">
            <div className="cmp--stepicon">
              <img src={contractIcon} loading="lazy" alt="contract icon" className="img--stepico _1" />
            </div>
            <h2 className="h--s txt--green">{t('contract')}</h2>
            <p>{t('contract-text')}</p>
          </div>
          <div className="cmp--arrow">
            <i className="fa-solid fa-arrow-right"></i>
          </div>
          <div className="cmp--step">
            <div className="cmp--stepicon">
              <img src={startIcon} loading="lazy" alt="start icon" className="img--stepico" />
            </div>
            <h2 className="h--s txt--green">{t('kickoff')}</h2>
            <p>{t('kickoff-text')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NextSteps;
