import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

import './Carousel.scss';

const testimonials = [
  {
    name: 'Janina',
    text: 'testimonials-01',
  },
  {
    name: 'Tina',
    text: 'testimonials-02',
  },
  {
    name: 'Emily',
    text: 'testimonials-03',
  },
];

const Carousel = () => {
    const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <p className="testimonial-text">"{t(testimonial.text)}"</p>
            <p className="testimonial-name">- {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
