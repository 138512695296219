import React from "react";
import { useTranslation } from "react-i18next";

import Jumbotron from "../components/Jumbotron";
import Divider from "../components/Divider";

const SectionOne = () => {
  const { t } = useTranslation();
  return (
    <>
      <Jumbotron />
      <div style={{"height" : "32px", "width" : "100%", "background" : "#fff"}}></div>
      <Divider title={t("divider-2")} showIcon />
    </>
  );
};

export default SectionOne;
