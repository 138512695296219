import React from 'react';

import FooterBrandLink from '../FooterBrandLink';
import FooterNavigation from '../FooterNav';
// import SocialMediaLinks from '../SocialMediaLinks';

import './Footer.scss';

const Footer = () => (
  <footer className="section-footer">
    <div className="footer-container">
      <div className="image-section">
        <FooterBrandLink rotate="horizontal" position="left" />
      </div>
      <FooterNavigation />
      {/* <SocialMediaLinks /> */}
    </div>
  </footer>
);

export default Footer;
