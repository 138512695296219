import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

// import Brief from '../Brief';
import cute from '../../assets/images/cute.webp';
import Icon from "../Icon";
import './AboutUs.scss';

const useIntersectionObserver = (options) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [options]);

  return [ref, isVisible];
};

const AboutUs = () => {
  const { t } = useTranslation();
  const [ref1, isVisible1] = useIntersectionObserver({ threshold: 0.1 });
  const [ref2, isVisible2] = useIntersectionObserver({ threshold: 0.1 });
  const [ref3, isVisible3] = useIntersectionObserver({ threshold: 0.1 });
  const [ref4, isVisible4] = useIntersectionObserver({ threshold: 0.1 });
  const [ref5, isVisible5] = useIntersectionObserver({ threshold: 0.1 });
  const [ref6, isVisible6] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <>
    {/* <Brief /> */}
    <div className="about-us-section" id="about-section">
      <div className="about-us-container">
        <div className="txt--about">
          <p className={`txt--abouttitle ${isVisible1 ? "visible" : ""}`} ref={ref1}>
          {t("welcome-to")} <span className="pink">{t("desired").toLocaleUpperCase()}</span>
          <br />
          <span className="small">{t("your-partner")}</span>
          <br />
          <br />
          <div className="hide-mobile"><Icon color="#ff9ec8" position="left" /></div>
          <div className="hide-desktop"><Icon color="#ff9ec8" position="middle" /></div>
          </p>
          <p className={`txt--aboutmini ${isVisible2 ? "visible" : ""}`} ref={ref2}>
          {t("about-text-1")}
          </p>
          <p className={`txt--aboutmini ${isVisible3 ? "visible" : ""}`} ref={ref3}>
          {t("about-text-2")}
          </p>
          <p className={`txt--aboutmini ${isVisible4 ? "visible" : ""}`} ref={ref4}>
          {t("about-text-3")}
          </p>
          <p className={`txt--aboutmini ${isVisible5 ? "visible" : ""}`} ref={ref5}>
          {t("about-text-4")}
          </p>
          <p className={`txt--aboutmini ${isVisible6 ? "visible" : ""}`} ref={ref6}>
          </p>
        </div>
      </div>
      <div className="img-container">
        <img src={cute} alt="cutety pie" />
      </div>
    </div>
    </>
  );
};

export default AboutUs;
