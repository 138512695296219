import React from 'react';
import classnames from 'classnames';

import styles from './Heading.scss';

const Heading = ({
  children,
  className,
  level,
  style,
  tag = 'h',
  ...props
}) => {
  const tagName = tag === 'h' ? `h${level}` : tag;
  const Tag = tagName;

  const roleProps = !['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tagName)
    ? {
        role: 'heading',
        'aria-level': level,
      }
    : {};

  return (
    <Tag
      {...props}
      {...roleProps}
      className={classnames(styles[`h${level}`], className)}
      style={style}
    >
      {children}
    </Tag>
  );
};

export default Heading;
