import React from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import About from "./components/About";
import AboutUs from "./components/AboutUs";
import Carousel from "./components/Carousel";
import Consent from "./components/Consent";
import Contact from "./components/Contact";
import Divider from "./components/Divider";
import Footer from "./components/Footer";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";
import NavBar from "./components/Navbar";
import NextSteps from "./components/NextSteps";
import ScrollToTop from "./components/ScrollToTop";
import ServiceSection from "./components/Services";
import ServicePreview from "./components/ServicePreview";
import SectionOne from "./sections/section-one";
import SectionOneSubpages from "./sections/section-one-subpages";

import "./App.scss";

const App = () => {
  const { t } = useTranslation();

  return (
    <div className="App">
      <Consent />
      <NavBar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <SectionOne />
              <About />
              <Divider
                title={t("divider-3")}
                /* title={t("client-title").toLocaleUpperCase()}
                subtitle={t("we-help-1").toLocaleUpperCase()} */
                showIcon
              />
              <ServicePreview />
              <Carousel />
              <Contact />
            </>
          }
        />
        <Route
          path="about"
          element={
            <>
              <SectionOneSubpages subtitle={t("divider-4")} />
              <AboutUs />
              {/* <Divider title={t("client-title").toLocaleUpperCase()} showIcon /> */}
              <Carousel />
              <Contact />
            </>
          }
        />
        <Route
          path="service"
          element={
            <>
              <SectionOneSubpages subtitle={t("divider-5")} />
              <ServiceSection />
              {/* <Divider
                title={t("client-title").toLocaleUpperCase()}
                subtitle={t("we-help-1").toLocaleUpperCase()}
              /> */}
              <Carousel />
              <Contact />
            </>
          }
        />
        <Route
          path="contact"
          element={
            <>
              <SectionOneSubpages subtitle={t("divider-6")} />
              <NextSteps />
              <Carousel />
              <Contact />
            </>
          }
        />
        <Route
          path="impressum"
          element={
            <>
              <SectionOneSubpages subtitle={t("divider-4")} />
              <Impressum />
              <Carousel />
              <Contact />
            </>
          }
        />
        <Route
          path="datenschutz"
          element={
            <>
              <SectionOneSubpages subtitle={t("divider-4")} />
              <Datenschutz />
              <Carousel />
              <Contact />
            </>
          }
        />
      </Routes>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default App;
