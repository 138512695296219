import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import './ContactForm.scss';

const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    reason: 'general', // Adding reason to the state
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);

  emailjs.init({
    publicKey: "_-XgASr532vCyLD6w",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can add the logic to send the form data to a server
    //console.log('Form submitted:', formData);

    emailjs.sendForm('service_brctbck', 'contact_form', "#contact-form")
    .then(() => {
        //console.log('SUCCESS!');
    }, (error) => {
        //console.log('FAILED...', error);
    });
    
    setSubmitted(true);
  };

  return (
    <div className="contact-form-container">
      {submitted ? (
        <div className="thank-you-message">
          <h2>{t("thanks").toLocaleUpperCase()}</h2>
          <p>{t("back2u").toUpperCase()}</p>
        </div>
      ) : (
        <form id="contact-form" className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder='Name'
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder='E-Mail'
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="reason">{t("reason-title")}: </label>
            <select
              id="reason"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              required
            >
              <option value="general">{t("general-enquiry")}</option>
              <option value="apply">{t("apply-to-join")}</option>
            </select>
          </div>
          <div className="form-group">
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder='Message'
              required
            />
          </div>
          <Button backgroundColor='#fc95ce' size='medium' type="submit" text="Submit">{t("send").toLocaleUpperCase()}</Button>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
