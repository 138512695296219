import React from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

const Button = ({ onClick, children, size= 'medium', backgroundColor }) => (
  <button className={`custom-button ${size} ${backgroundColor}`} onClick={onClick}>
    {children}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Button;
