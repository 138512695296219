import React from "react";
import { useTranslation } from "react-i18next";

import "./Lang.scss";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const languages = {
    en: "EN",
    de: "DE",
  };

  const handleChange = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="language-switcher">
      {Object.entries(languages).map(([value, label]) => (
        <span
          key={value}
          className={`language-option ${
            i18n.language === value ? "active" : ""
          }`}
          onClick={() => handleChange(value)}
        >
          {label}
        </span>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
