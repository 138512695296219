import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import "./Consent.scss";

const ConsentComponent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // Check if consent has already been given
    const consentGiven =
      Cookies.get("userConsent") || localStorage.getItem("userConsent");
    if (!consentGiven) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Set cookie and local storage to indicate consent
    Cookies.set("userConsent", "true", { expires: 365 });
    localStorage.setItem("userConsent", "true");
    setIsVisible(false);
  };

  return (
    <div className={`consent-wrapper ${isVisible ? "visible" : "hidden"}`}>
      <div className="consent-content">
        <p>{t("consent")}</p>
        <button className="consent-button" onClick={handleAccept}>
          {t("accept")}
        </button>
      </div>
    </div>
  );
};

export default ConsentComponent;
