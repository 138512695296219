import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const Icon = ({ color = '#000', position = 'left' }) => {
  return (
    <div className={`icon icon--${position}`} style={{ backgroundColor: color }}></div>
  );
};

Icon.propTypes = {
  color: PropTypes.string,
  position: PropTypes.oneOf(['left', 'middle', 'right']),
};

export default Icon;
