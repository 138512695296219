import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import enFaqs from '../../i18n/en.json';
import deFaqs from '../../i18n/de.json';
import './FAQ.scss';

const FAQ = () => {
  const { i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = i18n.language === 'de' ? deFaqs.faqs : enFaqs.faqs;

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section" id="faq-section">
      {faqs.map((faq, index) => (
        <div className="faq-item" key={index}>
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <i className={`fas ${activeIndex === index ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
            <span>{faq.question}</span>
          </div>
          <div className={`faq-answer ${activeIndex === index ? 'open' : ''}`}>
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
