import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import './FooterBrandLink.scss';

const FooterBrandLink = ({ rotate = 'horizontal', position = 'left' }) => {
  const navigate = useNavigate();
  const rotationClass = rotate === 'vertical' ? 'vertical' : 'horizontal';
  const positionClass = `position-${position}`;


  const goHome = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button className={`footer-brand-link ${rotationClass} ${positionClass} bold`} onClick={goHome}>
      <span>DESIRED.</span>
    </button>
  );
};

FooterBrandLink.propTypes = {
  rotate: PropTypes.oneOf(['horizontal', 'vertical']),
  position: PropTypes.oneOf(['left', 'center', 'right']),
};

export default FooterBrandLink;
