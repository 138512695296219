import React from "react";
import { useTranslation } from "react-i18next";

import Topimage from "../components/Topimage";
import Divider from "../components/Divider";

const SectionOneSubpages = ({subtitle = ""}) => {
  const { t } = useTranslation();

  if(subtitle == ""){
    subtitle = t("divider-4");
  }

  return (
    <>
      <Topimage />
      <Divider title={subtitle} showIcon />
    </>
  );
};

export default SectionOneSubpages;
