import React from 'react';
import { useTranslation } from 'react-i18next';

import './Card.scss';

const Card = ({ title, description, icon }) => {
const { t } = useTranslation();

	return (
		<div className="card-container">
				<div className="card-icon">
						<img src={icon} loading="lazy" alt='icon' />
				</div>
				<h3 className="green-title">{t(title)}</h3>
				<p className='text-center'>{t(description)}</p>
			</div>
	)
}

export default Card;