import React from "react";
import PropTypes from "prop-types";

import "./Divider.scss";

const Divider = ({ bgColor = "", textColor = "#f3f3f3", title = "", subtitle = "", showIcon = false }) => {
  if(bgColor == ""){
    bgColor = "linear-gradient(135deg, #ff73bb, #00b5a9)";
  }
  
  return (
    <div className="divider" style={{ background: bgColor, color: textColor }}>
      <div className="divider-content">
        <p className="divider-title">{title}</p>
        {subtitle && <p className="divider-subtitle">{subtitle}</p>}
        {showIcon && <div className="icon"></div>}
      </div>
    </div>
  );
};

Divider.propTypes = {
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showIcon: PropTypes.bool,
  icon: PropTypes.string,
};

export default Divider;
