import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BrandLink from "../BrandLink";
import LanguageSwitcher from "../Lang";
import "./Navbar.scss";

const Navbar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = (path, sectionId) => {
    navigate(path);

    setTimeout(() => {
      if (sectionId) {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo(0, 0);
      }
    }, 0);
  };

  return (
    <header className="header-transparent full-width">
      <nav className="navbar navbar-expand-lg text-center">
        <div className="container">
          <BrandLink className="brand-link" rotate="vertical" position="left" />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <p className="nav-link" onClick={() => handleNavigation('/', null)}>
                  {t("home").toUpperCase()}
                </p>
              </li>
              <li className="nav-item">
                <p className="nav-link" onClick={() => handleNavigation('/about', 'about-section')}>
                  {t("about").toUpperCase()}
                </p>
              </li>
              <li className="nav-item">
                <p className="nav-link" onClick={() => handleNavigation('/service', 'service-section')}>
                  {t("services").toUpperCase()}
                </p>
              </li>
              <li className="nav-item">
                <p className="nav-link" onClick={() => handleNavigation('/contact', 'contact-section')}>
                  {t("contact").toUpperCase()}
                </p>
              </li>
            </ul>
          </div>
          <LanguageSwitcher />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
